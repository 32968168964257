import React from "react";
import "@/styles/globals.css";
import "/src/styles/prism-atom-dark.css";
import type { AppProps } from "next/app";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import NextNProgress from "nextjs-progressbar";

const theme = extendTheme({
  colors: {
    purple: {
      1000: '#5046C0',
      900: '#7367F0',
      800: '#8176F1',
      700: '#8F85F3',
      600: '#9D95F5',
      500: '#ABA4F6',
      400: '#B9B3F7',
      300: '#C7C2F9',
      200: '#D5D1FB',
      100: '#E3E1FC',
      50: '#FAF5FF',
    }
  },
  styles: {
    global: {
      body: {
        minHeight: "100vh",
        backgroundColor: "gray.50",
      },
      ".onBanner + .show": {
        display: "block",
      },
      ".share-wrapper button:hover": {
        backgroundColor: "#FAF5FF !important",
      },
    },
  },
});

export default function App({ Component, pageProps }: AppProps) {
  return (
    <ChakraProvider theme={theme}>
      <NextNProgress height={3} />
      <Component {...pageProps} />
    </ChakraProvider>
  );
}
